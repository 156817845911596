import React from "react"
import { MobXProviderContext } from "mobx-react"
import Keycloak from "keycloak-js"
import { action, observable } from "mobx"
import { BASE_PATH } from "@consts/config"

export class AuthState {
  keycloak: Keycloak | null = null
  @observable isAuthenticated: boolean = false
  @observable initialized = false

  @action
  async initialize() {
    this.keycloak = new Keycloak({
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!,
      realm:    process.env.REACT_APP_KEYCLOAK_REALM!,
      url:      process.env.REACT_APP_KEYCLOAK_URL!
    })
    try {
      const authenticated = await this.keycloak.init({})
      this.isAuthenticated = authenticated
      if (authenticated) {
        localStorage.setItem("JWT_KC_MODE", String(true))
        localStorage.setItem("JWT_ACCESS", this.keycloak.token!)
        localStorage.setItem("JWT_REFRESH", this.keycloak.refreshToken!)
        localStorage.setItem("KC_ID_TOKEN", this.keycloak.idToken!)

        if (
          this.keycloak.tokenParsed !== undefined &&
          this.keycloak.tokenParsed["exp"] !== undefined
        ) {
          localStorage.setItem(
            "JWT_EXPIRY",
            String(this.keycloak.tokenParsed["exp"] * 1000)
          )
        }
      }
      this.initialized = true
    } catch (error) {
      console.error("Failed to initialize keycloak adapter:", error)
      this.initialized = false
    }
  }

  @action
  async login() {
    // clean up jwt token that might be set by idp login
    localStorage.removeItem("JWT_ACCESS")

    if (this.keycloak === null) {
      return null
    }

    var loginUrl = this.keycloak.createLoginUrl({
      prompt:      "login",
      redirectUri: this.generateRedirectUrl()
    })
    const wlCode = window.theme?.urlName ? window.theme.urlName : "default"
    loginUrl += `&wl_code=${wlCode}`
    window.location.assign(loginUrl)
  }

  generateRedirectUrl() {
    const profile = window.theme?.urlName
    return window.location.origin + BASE_PATH + (profile ? `${profile}/` : "")
  }

  @action
  async logout() {
    if (this.keycloak === null) {
      return
    }

    await this.keycloak.logout({
      redirectUri: this.generateRedirectUrl()
    })
  }

  @action
  async getAccessToken() {
    if (this.keycloak === null) {
      return null
    }

    return this.keycloak.token
  }

  async redirectToHome() {
    const paths = window.location.pathname.split("/")
    window.location.pathname =
      paths.length > 1 && paths[1] === "home" ? "/home" : "/"
  }
}

export const authState = new AuthState()

export function useAuthState(): AuthState {
  return React.useContext(MobXProviderContext).authState
}
